<div class="container_expand login-page ">
    <div class="d-flex justify-content-center h-100">
        <div class="user_card">
            <div class="d-flex justify-content-center">
                <div class="brand_logo_container">
                    <img src="../../../assets/blusmartlogo-dark.png" class="brand_logo" alt="Logo">
                </div>
            </div> 
            <div class="d-flex justify-content-center form_container">
                <form #f='ngForm' (ngSubmit)='login(f)' autocomplete="off">
                    <div class="input-group mb-3">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                        </div>
                        <input pInputText type="text" name="username" class="form-control input_user"   value="" placeholder="username"  ngModel>
                    </div>
                    <div class="input-group mb-2">
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                        </div>
                        <input pInputText type="password" name="password" class="form-control input_pass"     value="" placeholder="password"  ngModel>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customControlInline">
                            <label class="custom-control-label" for="customControlInline">Remember me</label>
                        </div> 
                    </div>
                        <div class="d-flex justify-content-center mt-3 login_container">
                 <button type="submit" name="button"class="btn login_btn"  >Login</button>
               </div>
                </form>
              
               <br/>
            </div>
            <p style="color: #ae2e2e;font-size: 14px; padding: 10px;">*Password login has been , Please use Google to login</p>
            <!-- <div class="mt-4"> -->
                <!-- <div class="d-flex justify-content-center links">
                    Don't have an account? <a href="#" class="ml-2">Sign Up</a>
                </div> -->
                <hr style="background:white;width: 80%;align-items: center;">
               
                <div class="d-flex justify-content-center links">
                    <!-- <a href="#">Forgot your password?</a> -->
                    <div #gbutton></div>
                   
                    <!-- <button type="button" class="btn btn-danger" (click)="signInWithGoogle()"><i class="fab fa-google"></i> &nbsp; Login with Google</button> -->
                    <br/>
                </div>
            <!-- </div> -->
        </div>
    </div>
</div>