import {
  API_URL_GET_ALL_CONFIGURE_RULES, API_URL_GET_RULES_ID_WISE,
  API_URL_INSERT_CONFIGURATION_RULE, API_URL_UPDATE_CONFIGURATION_RULE,
  API_UPLOAD_KML_FILE,API_GET_SOURCE,
  API_URL_CREATE_LEASE_CONFIG,API_URL_GET_ALL_LEASE_CONFIGURATION, API_URL_UPDATE_LEASE_CONFIG, 
  API_URL_DELETE_LEASE_CONFIG, API_URL_GET_PROMO_CONFIG, API_URL_CREATE_PROMO_CONFIG, API_URL_ACTIVATE_PROMO_CONFIG,
   API_URL_UPDATE_PROMO_CONFIG, API_GET_OVERWRITING_FARE, API_UPLOAD_FLAGS_CSV, API_URL_GET_RENTAL_PACKAGES, 
   API_URL_GET_BUSINESS_ACCOUNTS, API_URL_GET_SERVICE_REGION, API_URL_GET_SUB_REGION, API_URL_GET_ZONES, 
   API_UPLOAD_BLUKMS_CSV,API_GET_OVERWRITING_FARE_DISTANCE,API_UPLOAD_BULK_REFUNDS,API_NEWSROOM_DATA,API_NEWSROOM_EXCEL
} from '../../config/apiRouteConfig/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}
@Injectable()
export class SettingService {
  callListApi= true;

  constructor(private _http: HttpClient) { }

  getAllConfigureRules() :Observable<any>{
    return this._http
    .get(API_URL_GET_ALL_CONFIGURE_RULES);
  }
  getRulesIdWise(ruleName) :Observable<any>{
    return this._http.get(`${API_URL_GET_RULES_ID_WISE}rule_name=${ruleName}`)
  }
  // insertConfigureRule() :Observable<any>{
  //   return this._http.post(API_URL_INSERT_CONFIGURATION_RULE);
  // }
  insertConfigureRule(data){
    return this._http.post(API_URL_INSERT_CONFIGURATION_RULE, data);
  }
  updateConfigureRule(data){
    return this._http.post(API_URL_UPDATE_CONFIGURATION_RULE, data);
  }

  uploadFile(file): Observable<any>{
    return this._http.post(API_UPLOAD_KML_FILE, file);
  }

  generateNewLeaseConfiguration(data){
    return this._http.post(API_URL_CREATE_LEASE_CONFIG, data)
  }

  updateLeaseConfiguration(data){
    return this._http.post(API_URL_UPDATE_LEASE_CONFIG, data)
  }

  deleteLeaseConfigScheduler(leaseConfigId){
    return this._http.delete(`${API_URL_DELETE_LEASE_CONFIG}leaseConfigId=${leaseConfigId}`)
  }
  getAllLeaseConfihuration(){
    return this._http.get(API_URL_GET_ALL_LEASE_CONFIGURATION);
  }
  getAllPromoConfiguration(){
    return this._http.get(API_URL_GET_PROMO_CONFIG);
  } 
  createPromoScreen(data){
    return this._http.post(API_URL_CREATE_PROMO_CONFIG,data)
  }
  activatePromo(id){
    return this._http.post(API_URL_ACTIVATE_PROMO_CONFIG+"?id="+id,id)
  }
  updatePromo(id,promoDto){
    return this._http.post(API_URL_UPDATE_PROMO_CONFIG+"?id="+id,promoDto)
  }

  uploadRiderFlagsCSVFile(selectedFlagType, fileToUpload){
    if(selectedFlagType =="BULK_CANCEL")
      return this._http.post(API_UPLOAD_BULK_REFUNDS, fileToUpload)
    return this._http.post(API_UPLOAD_FLAGS_CSV+"/"+selectedFlagType, fileToUpload)
  }
  uploadNewsroomData(fileToUpload){
    return this._http.post(API_NEWSROOM_DATA,fileToUpload);
  }
  uploadNewsroomExcel(fileToUpload){
    return this._http.post(API_NEWSROOM_EXCEL,fileToUpload);
  }

  uploadBluKmsCSVFile(fileToUpload){
    return this._http.post(API_UPLOAD_BLUKMS_CSV, fileToUpload)
  }
  


  //overwriting fare API's

  getOverwritingFares(){
    return this._http.get(API_GET_OVERWRITING_FARE)
  }
  getOverwritingFairDistance(){
    return this._http.get(API_GET_OVERWRITING_FARE_DISTANCE)
  }
  getOverwritingFareById(id){
    return this._http.get(API_GET_OVERWRITING_FARE+"/"+id)
  }
  createOverWritingFare(body){
    return this._http.post(API_GET_OVERWRITING_FARE,body)
  }
  createOverWritingDistance(body){
    return this._http.post(API_GET_OVERWRITING_FARE_DISTANCE,body)
  }
  updateOverWrtingFare(id,body){
    return this._http.put(API_GET_OVERWRITING_FARE+"/"+id,body)
  }
  deleteOverWritingFare(id){
    return this._http.delete(API_GET_OVERWRITING_FARE+"/"+id)
  }
  deleteOverWritingDistance(id){
    return this._http.delete(API_GET_OVERWRITING_FARE_DISTANCE+"/"+id)
  }
  updateFair(id,body){
    return this._http.put(API_GET_OVERWRITING_FARE+"/"+id,body)
  }
  updateCustomDistance(id,body){
    return this._http.put(API_GET_OVERWRITING_FARE_DISTANCE+"/"+id,body)
  }

  getSources(){
    return this._http.get(API_GET_SOURCE);
  }

  getRentalPackages(businessAccounts, category){
    return this._http.get(API_URL_GET_RENTAL_PACKAGES+'?source='+ businessAccounts.toString() +'&category='+ category);
  }

  getBusinessAccounts(){
    return this._http.get(API_URL_GET_BUSINESS_ACCOUNTS);
  }

  getServiceRegions(zoneId){
    return this._http.get(API_URL_GET_SERVICE_REGION+'?zoneId='+zoneId);
  }

  getSubRegion(serviceRegionId){
    return this._http.get(API_URL_GET_SUB_REGION+'?serviceRegionId='+serviceRegionId);
  }

  getZones(){
    return this._http.get(API_URL_GET_ZONES);
  }
}
