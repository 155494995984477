import { AppService } from './../../../services/app.service';
import { Component, OnInit, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PermissionForUsers } from 'src/app/config/apiRouteConfig/checkroles';
import { COOKIE_URL, COOKIE_SUFFIX } from 'src/app/config/apiRouteConfig';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnChanges {
  collapse: boolean = true;
  isEnableMultiSelect: boolean = true;
  displayCreateTicket: boolean = false;

  zonesList: any[] = [];
  selectedZones = [];
  selectedZone: string;

  singleSelectList = ['/rides/liverides/beta','/livemap','/setting','/driver-on-duty','/journey-details','/reports','/vehicle', '/livemapbeta','/overspeeding','/rider-new','/overwriting_fare']

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  constructor(public appService: AppService, private _router: Router, private _cookieService: CookieService,private el: ElementRef,private route: ActivatedRoute) { 
    this._router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        console.log(event.url)
        if(event.url === '/livemapbeta' || event.url === '/livemap' || event.url ===  '/setting' || event.url ==='/driver-on-duty' || event.url === '/overwriting_fare' || event.url === '/journey-details' || event.url==='/reports' || event.url ==='/vehicle' || event.url == '/overspeeding' || event.url == '/rider-new' || event.url == '/slots'
         || event.url === '/rides/liverides/beta' ){
          localStorage.setItem('lastVisitedUrl',event.url);
          this.isEnableMultiSelect = false;
          let serviceIds = this.appService.getCurrentServiceRegionId();
          this.appService.selectedZone = serviceIds[0];
          this.appService.setCurrentServiceRegionId(this.appService.selectedZone); 
        }else{
          localStorage.setItem('lastVisitedUrl',null);
          let zoneId = localStorage.getItem('zoneId');
          if(zoneId){
            this.appService.selectedZones = zoneId.split(",");
            this.appService.setCurrentServiceRegionId(zoneId.split(",")); 
          }
          
          this.isEnableMultiSelect = true;
        }  
        
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
      if(this.el.nativeElement.contains(event.target)) {
      } else {
        this.collapse = true;
      }
  }
  openSlotsVisual(){
   
    console.log('open slots');
    this._router.navigate(['rides/slotsVisual'])
    
  }
  getAllZones(){
    this.appService.getAllZones('119').subscribe((res:any) => {
      Object.entries(res.response).forEach((ele) => { 
        let zone = {
          "label": ele[1],
          "value": ele[0]
        }
         this.zonesList.push(zone);
      });
      localStorage.setItem("zonesList",JSON.stringify(this.zonesList))
      this.selectedZones.push(this.zonesList[0].value);
      this.selectedZone = this.zonesList[0].value;
    
      
    })
  }

  ngOnInit() {
    let zoneId = localStorage.getItem('zoneId');
    this.appService.setCurrentServiceRegionId(zoneId);
    if(zoneId== 'null' || !zoneId){
      this.logout();
      return
    }
    if(zoneId && zoneId.length == 1 && localStorage.getItem('lastVisitedUrl') != 'null' && localStorage.getItem('lastVisitedUrl') != null){
      this.isEnableMultiSelect = false;
      this.appService.selectedZone = zoneId;
    }else{
      this.isEnableMultiSelect = true;
      this.appService.selectedZones = zoneId.split(",");
    } 
     this.appService.getAllZones(localStorage.getItem('ssoId')).subscribe((res:any) => {
            if(res.status == 'SUCCESS'){
                  this.appService.zonesList =[]
                  Object.entries(res.response).forEach((ele) => {
                    let zone = {
                        "label": ele[1],
                        "value": ele[0]
                    }
                    this.appService.zonesList.push(zone);
                    localStorage.setItem("zonesList",JSON.stringify(this.appService.zonesList));
                  })
                }
              })

  }

  closeNav(url) {
 
    if(url === '/login' )
       return
    this.collapse = true;
    localStorage.setItem('lastVisitedUrl',url);
     let zoneId = localStorage.getItem('zoneId');
   
    if(url === '/livemap' || url === '/livemapbeta' || url ===  '/setting' || url ==='/driver-on-duty' || url === '/journey-details' || url==='/reports' || url ==='/vehicle' || url === '/overwriting_fare' || url =='/overspeeding' || url == '/rider-new' || url == 'slots' || url == 'sos-management' || url == 'home-charging' ){
      this.isEnableMultiSelect = false;
      let serviceIds = this.appService.getCurrentServiceRegionId();
      this.appService.selectedZone = serviceIds[0];
      this.appService.setCurrentServiceRegionId(this.appService.selectedZone); 
    }else{
      if(zoneId && zoneId.length > 1){
        this.appService.selectedZones = zoneId.split(",");
        this.appService.setCurrentServiceRegionId(zoneId.split(",")); 
      }
       
      this.isEnableMultiSelect = true;
    }    
  }

  openNav() {
    this.collapse = !this.collapse;
  }

  logout() {
    this.appService.logout().subscribe(
      (response) => {
        this._cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
        this._cookieService.set("accessToken"+COOKIE_SUFFIX, null, 1, "/",COOKIE_URL,true,"Lax");
        this.appService.isValid = false;
        localStorage.clear();
        this.appService.name = null
        this._router.navigateByUrl('/login');
      },
      (err) => {
        //console.log(err);
      }
    )
  }

  ngOnChanges(){
    console.log(this._router);
  }

  onRideTypeChange(value) {
    if (value == 'all')
      value = null
    this.appService.setRideType(value);
    this._router.navigate(["rides"])
    this._router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
      setTimeout(() => { this._router.navigate(["/rides"]) }, 10);
    });
  }

  onServiceRegionChange(value) {
    if(value.length == 0){
      this.appService.selectedZones = [this.appService.zonesList[0].value];
      value = [this.appService.zonesList[0].value]
    }
    
    this.appService.setCurrentServiceRegionId(value);
    this.appService.selectedZones = value

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([this._router.url]);

    // if (location.pathname.indexOf('liverides')>=0) {
    //   this._router.navigateByUrl('/rides/schedulerides', { skipLocationChange: true }).then(() => {
    //     this._router.navigate([location.pathname]);
    //   });
    // } else {
    //   this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //     this._router.navigate([location.pathname]);
    //   });
    // }

  }
  openCreateTicketDailog(){
    this.displayCreateTicket = true
  }

}
