import { AppService } from './../../services/app.service';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { COOKIE_URL,COOKIE_SUFFIX, isProdEnv } from 'src/app/config/apiRouteConfig';
import { CredentialResponse } from 'google-one-tap';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  private user;
  private loggedIn: boolean=false;
  private gmailLoggedin:boolean = false;
  @ViewChild('gbutton', { static: true }) gbutton: ElementRef = new ElementRef({});
  constructor(private _cookieService: CookieService,private _appService: AppService,private router:Router,private route: ActivatedRoute,private ngZone: NgZone) {
  }


  ngOnInit() {
    google.accounts.id.initialize({
      client_id: '393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com',
      callback: this.handleCredentialResponse.bind(this),
      auto_select: true,
      cancel_on_tap_outside: false
    });
    google.accounts.id.renderButton(
      this.gbutton.nativeElement,
      {
      type: "standard", theme: "filled_black",
      size: "large", width: 50, shape: "pill",
      }

  
  )
  
  if(isProdEnv)
  google.accounts.id.prompt();
    const token = this._cookieService.get('accessToken'+COOKIE_SUFFIX);
    
    if(token){
       this.router.navigateByUrl('/');
    }else{
      this._appService.isValid=false;
    }
   }
   handleCredentialResponse(response: CredentialResponse) {
    let decodedToken: any | null = null;
    try {
      this.ngZone.run(() =>  this.login({value:{username:null,password:null,token:"gmail "+response.credential}}));
      decodedToken = JSON.parse(atob(response.credential.split('.')[1]));
      if(decodedToken.given_name){
        localStorage.setItem("name",decodedToken.given_name+" "+decodedToken.family_name)
        localStorage.setItem("gmail",decodedToken.email)
      }

    } catch (e) {
      console.error('Error while trying to decode token', e);
    }
  }



  login(data:any){
    this._appService.login(data.value).subscribe(
      (responseServer)=>{
        //  console.log(responseServer);
         if(responseServer.statusCode !== 200){
          //
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  responseServer.message || 'Something went wrong!',
          });
         }
         else{
          const { response: cookieData } = responseServer;
          this._cookieService.delete('accessToken'+COOKIE_SUFFIX, '/',COOKIE_URL);
          this._cookieService.set("accessToken"+COOKIE_SUFFIX, cookieData.access_token, cookieData.expires_in, "/",COOKIE_URL,true,"Lax");
          this._appService.isValid = true;     
          this.router.navigateByUrl('/rides/liverides/beta');
          if(this._appService.name ==null)
          this._appService.checkToken().subscribe(
            (res: any) => {
              if (res.statusCode == 200) {
                let name;
                if(res.response.username){
                  let username = res.response.username
                  let usernameArr = username.split('.')
                  if(usernameArr.length == 3){
                    name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
                  }else{
                    usernameArr = username.split('@')
                    name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
                  }
                  this._appService.name = name;
              }
         }})
        }
      },
      (err)=>{
        //console.log(err);
      }
    )
  }
  

}
